.news {
  margin-top: 2.5em;
}

.news .list {
  margin-top: 2em;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  justify-content: space-evenly;
}

.arrow {
  color: chocolate;
  cursor: pointer;
  margin: auto 0;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

/* Aici apar sagetile */
@media (min-width: 1009px) {
  .arrow-right,
  .arrow-left {
    display: none;
  }

  .list-of-news {
    display: none;
  }
}
