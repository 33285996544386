.product-wrapper {
  padding: 12px;
  width: 268px;
  margin: 0 1em 2em 1em;
  border-radius: 3px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  background: white;
}
.product-image {
  width: 240px;

  height: 170px;
  display: inline-block;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 0;
  background-position: center;
  background-size: cover;
}
.product-content {
  padding: 2em;
  /* height: 15em; */
  height: 18em;
  width: 240px;
  text-align: left;
  padding: 7px;
  display: flex;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  flex-direction: column;
  border-radius: 3px;
  margin-top: 0;
}
.product-content h4 {
  font-weight: 300;
  font-size: 1.3em;
  display: inline-block;
  color: chocolate;
}
.product-content .text-wrapper {
  height: 100%;
  overflow: scroll;
  padding: 7px;
}
.product-content p {
  font-weight: normal;
  line-height: 1.6;
}
.product-content .date {
  margin: 1em 0 0 0;
}
.product-content .details span {
  color: chocolate;
  /* text-decoration: underline; */
}

.product-content .details span a {
  color: inherit;
  text-decoration: none;
  /* text-underline-position: under; */
  /* text-decoration-color: black; */
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  position: absolute;
  z-index: -1;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  position: absolute;
  background-color: chocolate;
  z-index: -1;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
