main {
  /* margin: 5em 13em 0px 13em; */
  width: 70%;
  margin: 0 auto;
}

@media (max-width: 1120px) {
  main {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  main {
    width: 70%;
  }
}
/* .section-title {
  font-weight: 300 !important;
} */
.section-title {
  color: chocolate;
  font-weight: 100;
  font-size: 2.5em;
  display: inline-block;
  /* border-bottom: 1px solid black; */
  font-weight: 700 !important;
}
