.new-wrapper {
  padding: 12px;
  margin: 0 1em 2em 1em;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.new-image {
  width: 195px;
  height: 150px;
  display: inline-block;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 0;
  background-position: center;
  background-size: cover;
}
.new-content {
  height: 15em;
  width: 240px;
  text-align: left;
  padding: 7px;
  display: flex;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  flex-direction: column;
  border-radius: 3px;
  margin-top: 0;
}
.new-content h4 {
  font-weight: 300;
  font-size: 1.3em;
  display: inline-block;
  color: chocolate;
}
.new-content .text-wrapper {
  height: 100%;
  overflow: scroll;
  padding: 7px;
}
.new-content p {
  font-weight: normal;
  line-height: 1.6;
}
.new-content .date {
  margin: 1em 0 0 0;
}
.new-content .date span {
  text-transform: uppercase;
  color: chocolate;
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-color: black;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  position: absolute;
  z-index: -1;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  position: absolute;
  background-color: chocolate;
  z-index: -1;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
