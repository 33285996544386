.header {
  /* height: 100em; */
  /* margin-top: 8em; */
  margin-top: 60px;
  margin-bottom: 5em;
  /* background: chocolate; */
  display: flex;
  justify-content: center;
  padding-top: 3em;
  padding-bottom: 3em;
  text-align: left;
}

.header__content {
  width: 70%;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: -moz-box;
  justify-content: space-evenly;
}

.main-title {
  color: chocolate;
  /* color: white; */
  font-weight: 100;
  font-size: 3em;
}

.header__content p,
.header__p {
  color: black;
  /* color: white; */
  font-size: 1.2em;
  margin: 1.6em 0px 1.6em 0px;
  line-height: 1.6;
}

.button-header {
  height: 2em;
  width: 7em;
  border: 1px solid white;
  border: 1px solid chocolate;
  background: transparent;
  cursor: pointer;
  font-size: 18px;
  font-weight: 100;
}

.button-header:focus {
  outline: none;
}

.full__button-header {
  background: chocolate;
  margin-right: 0.5em;
  color: white;
  /* color: black; */
}

.full__button-header:hover {
  /* color: chocolate; */
  color: black;
}

.empty__button-header {
  margin-left: 0.5em;
  /* color: white; */
}

.empty__button-header:hover {
  /* color: black; */
  background: white;
  background: chocolate;
}

.header__logo {
  height: 14em;
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, transparent), to(rgba(250, 250, 250, 0.1)));
}

/* .header .left {
  margin-left: 2
} */

@media (min-width: 1076px) {
  .header__image-mobile {
    display: none;
  }
}

@media (max-width: 1075px) {
  .header__content {
    display: block;

    text-align: center;
  }

  .header__image-desktop {
    display: none;
  }

  .left {
    z-index: 100;
  }

  .header__image-mobile {
    margin: 0 auto;
  }
}

@media (max-width: 500px) {
  .header__content .title {
    font-size: 2em;
  }
}
