* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* *::selection {
  background: chocolate;
} */

html {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}

body {
  height: 100%;
  /* overflow-y: hidden; */
  margin: 0;
  padding: 0;
  font-family: effra, sans-serif;
  font-style: normal;
  font-weight: 300;
  height: 100%;
}

button {
  transition: 0.4s;
}
