footer {
  /* margin-top: 8em; */
  /* background: rgb(239, 239, 239); */
  width: 100%;
}

footer .container {
  background: rgb(239, 239, 239);
  text-align: center;
  width: 70%;
  margin: 0 auto;
  padding: 12px;
  padding-top: 0;
  display: flex;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: space-evenly;
}

footer .footer-wrapper {
  margin: 0 auto;
  width: 70%;
  border-bottom: 1px solid #c55c21;
}

footer .footer-title h3 {
  font-size: 16px;
  color: chocolate;
}

footer .footer-links {
  margin-top: 1em;
  display: flex;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  justify-content: space-around;
  color: black;
}

footer .footer-links a {
  color: black;
}

footer .footer-links-left {
  text-align: left;
}

footer .footer-links-middle {
  align-items: center;
}

footer .footer-links-right {
  text-align: right;
}

footer .icon {
  transition: 0.3s;
}

footer .icon:hover {
  color: chocolate !important;
}

footer a:visited {
  color: black;
}

footer .footer-links a {
  text-decoration: none;
  transition: 0.3s;
  margin: 0.3em 0px 0.3em 0px;
}

footer .footer-links a:hover {
  color: chocolate;
}

footer .copyright {
  text-align: center;
  font-size: 12px;
  padding: 1em;
  color: black;
  background: rgb(239, 239, 239);
}

footer .copyright a {
  text-decoration: none;
  color: black;
}

footer .copyright a:hover {
  color: chocolate;
}

@media (max-width: 560px) {
  footer .section {
    margin-left: 1em;
    margin-right: 1em;
  }
}

.payment {
  padding-top: 1.3em;
  padding-bottom: 1.3em;
  display: flex;
  background: rgb(239, 239, 239);
  justify-content: center;
}

.payment img {
  height: 3em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

@media (max-width: 700px) {
  .payment img {
    height: 2em;
  }
}

@media (max-width: 400px) {
  .payment img {
    height: 1em;
  }
}
