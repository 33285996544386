.socials-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  background: black;
}

.socials {
  display: flex;
  justify-content: space-around;
  width: 70%;
  padding: 1em;
}

.socials a {
  display: block;
}

.socials .icon {
  /* color: #4d5061; */
  color: white;
  transition: 0.3s;
}

.socials .icon:hover {
  color: chocolate;
}
