.about {
  margin: 2em 0px 2em 0px;
}

.paragraph {
  margin-top: 2em;
  margin-bottom: 2em;
  line-height: 1.6;
  text-align: justify;
}
.about h2 {
  margin-top: 1.5em;
  color: chocolate;
}
