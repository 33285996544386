.toolbar {
  width: 100%;
  height: 60px;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background: transparent;
  height: 60px;
  /* background: black; */
  /* border-bottom: 1px solid #585858; */
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.6);
}

.toolbar__navigation {
  display: flex;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 1rem;
  /* background: black; */
  background: black;
  color: white;
  transition: 0.5s;
}

.navigation-scrolled {
  /* background: chocolate; */
  background: black;
  color: white;
}

.navigation-top {
  /* background: white; */
  background: black;

  /* color: chocolate; */
  color: white;
}

.toolbar__logo {
  /* border-bottom: 1px solid black; */
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.6);
  padding: 0.2em;
  padding-right: 0.4em;
  position: absolute;
  top: 30%;
  background: white;
  padding-left: 1.15em;
  margin-top: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbar__logo__mobile {
  box-shadow: 0 4px 4px -4px rgba(0, 0, 0, 0.6);
  padding: 0.2em;
  padding-right: 0.4em;

  display: none;
  background: white;
  padding-left: 1.15em;
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toolbar__logo a img,
.toolbar__logo__mobile a img {
  height: 5em;
}

.logo-top a img {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.toolbar__navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  /* margin-left: 17em; */
}

.toolbar__navigation-items li {
  padding: 0 0.5rem;
}

.toolbar__navigation-items a {
  text-decoration: none;
  font-size: 14px;
}

.ul-top li a {
  /* color: chocolate; */
  color: grey;
}

.ul-scrolled li a {
  /* color: white; */
  color: grey;
}

.ul-scrolled li a:hover {
  /* border-bottom: 1px solid black; */
  /* color: whitesmoke; */
  color: rgb(255, 106, 0);
}

.toolbar__navigation-items a:hover,
.toolbar__navigation-items a:active {
  color: rgb(255, 106, 0);
  /* border-bottom: 1px solid black; */
}

@media (max-width: 768px) {
  .toolbar__logo__mobile {
    display: block !important;
  }

  .toolbar__logo {
    display: none;
    visibility: hidden;
  }
  .toolbar__navigation-items {
    display: none;
  }
  .toolbar {
    position: fixed;
  }
  /* .spacer {
    flex: 1;
  } */
  .toolbar__navigation {
    justify-content: space-between;
  }
  .toolbar__logo {
    margin-left: 50%;
  }
}

/* @media (min-width: 1076px) {
} */

@media (min-width: 769px) {
  .toolbar__logo__mobile {
    display: none !important;
  }
  .toolbar__toggle-button {
    display: none;
  }

  .toolbar__logo {
    margin-left: 0;
  }
}

.language-flag {
  height: 1.7em;
  margin-left: 1em;
  cursor: pointer;
}

.icon {
  font-size: 1.5em !important;
  color: grey !important;
}

.icon-scrolled {
  color: grey !important;
}

.icon-scrolled:hover,
.icon:hover {
  color: rgb(255, 106, 0) !important;
}
