.products {
  /* width: 70%; */
  /* margin-top: 2em; */
  background: chocolate;
  padding-top: 3em;
}

.section-title {
  color: chocolate;
  font-weight: 100;
  font-size: 2.5em;
  display: inline-block;
}

.products .section-title {
  color: white;
}

.products .list {
  margin-top: 2em;
  text-align: center;
  display: flex;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  justify-content: space-evenly;
}

@media (max-width: 970px) {
  .products .list {
    flex-direction: column;
    align-items: center;
  }
}
