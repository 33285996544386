.side-drawer {
  height: 100%;
  background-color: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 250px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  text-align: center;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer ul {
  height: 75%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer li {
  margin: 1em 0;
}

.side-drawer li:hover,
.side-drawer li:hover a {
  color: white !important;
  background: chocolate;
  border: none;
}

.side-drawer a {
  border-bottom: 1px solid black;
  color: chocolate;
  text-decoration: none;
  font-size: 1.2rem;
}

.side-drawer-logo {
  height: 5em;
  margin-top: 1.5em;
}

@media (min-width: 769px) {
  .side-drawer {
    display: none;
  }
  .side-drawer-logo {
    display: none;
  }
}
