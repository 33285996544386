.AdminPage {
  display: flex;
  justify-content: center;
}

.AdminPage .login-form {
}

.AdminPage .login-form input {
  display: block;
  margin-top: 1em;
  border-radius: 20px;
}

.AdminPage .login-form input[type='password'] {
  border: 1px solid chocolate;
  padding: 0.5em;
  height: 30px;
  width: 250px;
}

.AdminPage .login-form input[type='submit'] {
  width: 80px;
  height: 30px;
  border: 1px solid chocolate;
  cursor: pointer;
  transition: 0.2s;
}

.AdminPage .login-form input[type='submit']:hover {
  background: rgb(170, 169, 169);
}

.AdminPage .login-form input[type='password']:focus,
.AdminPage .login-form input[type='submit']:focus {
  outline: none;
}
