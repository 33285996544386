.products-page .title {
  margin-top: 8em;
  margin-bottom: 5em;
}
html {
  height: 100%;
}

#products-body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: scroll !important;
}
#products-body::-webkit-scrollbar {
  display: none;
}
#react-modal-image-img {
  object-fit: cover;
  width: 60%;
}

.products-page .paragraph {
  display: block;
}

.products-page .paragraph::before {
  content: '\A';
  white-space: pre;
}

.products-page .images {
  /* display: flex;
  
  justify-content: center; */
  margin: 0 auto;
}

.products-page .bolded {
  font-weight: bold;
}

.products-page .images-container {
  display: block;
  margin-top: 1.7em;
  margin-bottom: 1.7em;
}
.products-page .images img {
  width: 15em;
  display: block;
  height: auto;
}

.products-page .images.last img {
  width: 18em;
  display: block;
  height: auto;
}

.products-page .main-title {
  font-weight: 500 !important;
}

@media (max-width: 1048px) {
  .products-page .images {
    flex-direction: column;
    align-items: center;
  }
  .products-page .images img {
    width: 100%;
  }
}

.modified img {
  width: 200px !important;
}
.products-page img {
  display: block;
}

.products-page div[title='Select'] {
  display: none;
}
