.contact-section {
  margin-left: 0;
}

.contact-section .p-wrapper {
  display: flex;
  justify-content: center;
}

.contact-section .header__p {
  padding: 1em;
  color: black;
  text-align: center;
  display: inline-block;
  border-left: 2px solid chocolate;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: 0.6s;
}

.contact-section .header__p:hover {
  border-color: black;
  color: chocolate;
}

.contact-section .text-break1,
.text-break2 {
  display: none;
}

.contact-form {
  text-align: center;
  margin-bottom: 1.5em;
}

.contact-form label {
  display: block;
  color: chocolate;
  margin-bottom: 0.75em;
  margin-top: 0.75em;
}

.contact-form label span {
  color: black;
}

.contact-form .message {
  margin: 0;
  border: default;
  width: 300px;
  height: 100px;
}

.contact-form input {
  width: 400px;
  height: 50px;
}

.contact-form textarea {
  width: 400px !important;
}

.contact-form button {
  cursor: pointer;
  font-size: 13px;
  margin: 0;
  margin-top: 1em;
  width: 150px;
  border: none;
  height: 40px;
  border-left: 2px solid chocolate;
  border-radius: 2px;
  transition: 0.6s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.contact-form button:hover {
  color: white;
  background: chocolate;
  border-color: black;
}

.contact-form input,
.contact-form textarea {
  text-align: left;
  font-size: 15px;
  padding: 3px 3px 3px 3px;
  border: none;
  border-left: 2px solid chocolate;
  border-radius: 2.5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  transition: 0.6s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none !important;
  border: initial;
  border-left: 2px solid black;
}

@media (max-width: 1010px) {
  .contact-section .text-break2 {
    display: block;
  }
}

@media (max-width: 682px) {
  .contact-section .text-break1 {
    display: block;
  }
}

@media (max-width: 575px) {
  .contact-form input,
  .contact-form textarea {
    width: 350px !important;
  }
}

@media (max-width: 400px) {
  .contact-form input,
  .contact-form textarea {
    width: 250px !important;
  }
}
