.legend {
  color: chocolate !important;
  font-size: 18px !important;
}

.carousel-container {
  margin: 0 auto;
  margin-top: 60px;
  display: flex;
  justify-content: center;

  /* max-width: 95%;
  height: 50em; */
}

@media (max-width: 768px) {
  .carousel-container {
    margin-top: 8em;
  }
}
